<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" rounded small>
        {{ $t("common.details") }}
      </v-btn>
    </template>

    <v-card class="report-details-modal">
      <v-card-title>
        <h3>{{ $t("reports.report") }}</h3>
        <v-spacer />
        <close-button @click="close" />
      </v-card-title>

      <v-divider />

      <v-card-text>
        <div class="fields mb-3">
          <div>
            <label>{{ $t("common.date") }}:</label>
            <span>{{ $utils.formatDate(report.created_at) }}</span>
          </div>

          <div>
            <label>{{ $t("reports.reporter") }}:</label>
            <span>
              <router-link
                v-if="report.reporter"
                :to="{
                  name: 'app.user',
                  params: { id: report.reporter.username },
                }"
                target="_blank"
                class="text-decoration-none-not-hover"
                >{{ report.reporter.username }}
              </router-link>
              <template v-else>-</template>
            </span>
          </div>

          <div>
            <label>{{ $t("common.type") }}:</label>
            <span>
              <ReportType :report="report" withCategory />
            </span>
          </div>

          <div>
            <label>{{ $t("common.content") }}:</label>
            <span>{{ getContent() }}</span>
          </div>
        </div>

        <router-link
          :to="getItemRoute()"
          target="_blank"
          class="primary--text font-weight-medium"
          >{{ $t("reports.viewItem") }}
        </router-link>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ReportType from "./ReportType.vue";

export default {
  components: { ReportType },

  props: {
    report: Object,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    getContent() {
      const item = this.report;

      switch (item.category) {
        case "post":
          return item.post ? item.post.content : "";

        case "comment":
          return item.comment ? item.comment.content : "";

        case "user":
          return item.user ? item.user.username : "";

        case "group":
          return item.group ? item.group.name : "";
      }
    },

    getItemRoute() {
      const item = this.report;

      switch (item.category) {
        case "post":
          return {
            name: "app.post",
            params: {
              id: item.post.id,
            },
          };

        case "comment":
          return {
            name: "app.post",
            params: {
              id: item.comment.post_id,
            },
          };

        case "user":
          return {
            name: "app.user",
            params: {
              id: item.user.username,
            },
          };

        case "group":
          return {
            name: "app.group",
            params: {
              id: item.group.id,
            },
          };
      }
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fields {
  > div {
    padding: 10px 0;

    label {
      display: inline-block;
      width: 150px;
      color: $gray-light;
    }
  }
}
</style>
