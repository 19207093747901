<template>
  <v-data-table
    :items="data"
    :headers="headers"
    :items-per-page.sync="perPage"
    :page.sync="page"
    :server-items-length="total"
    :loading="$loading('reports/fetch')"
    :footer-props="$config.vuetify.vDataTable.footerProps"
    @update:page="fetchData"
    @update:items-per-page="fetchData"
  >
    <template v-slot:top>
      <div class="mb-3 tab-buttons">
        <v-btn
          :color="typeFilter === null ? 'primary' : 'secondary'"
          rounded
          small
          class="tab-button mb-2"
          @click="typeFilter = null"
        >
          {{ $t("common.all") }}
        </v-btn>

        <v-btn
          v-for="type in $config.reports.types"
          :key="type"
          :color="typeFilter === type ? 'primary' : 'secondary'"
          rounded
          small
          class="tab-button px-3"
          @click="typeFilter = type"
        >
          {{ $t(`reports.types.${type}.title`) }}
        </v-btn>
      </div>
    </template>

    <!-- User -->
    <template v-slot:item.reporter="{ value }">
      <router-link
        v-if="value"
        :to="{ name: 'app.user', params: { id: value.username } }"
        target="_blank"
        class="text-decoration-none-not-hover"
      >
        {{ value.username }}
      </router-link>
      <template v-else>-</template>
    </template>

    <!-- Type -->
    <template v-slot:item.type="{ value, item }">
      <ReportType :report="item" withCategory />
    </template>

    <!-- Created_at -->
    <template v-slot:item.created_at="{ value }">
      {{ $utils.formatDate(value) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="d-flex">
        <ReportDetailsModal :report="item" @delete="fetchData" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import ReportDetailsModal from "./ReportDetailsModal.vue";
import ReportType from "./ReportType.vue";

export default {
  components: { ReportDetailsModal, ReportType },

  data() {
    return {
      typeFilter: null,
      data: [],
      page: 1,
      perPage: 10,
      total: null,
      headers: [
        {
          text: this.$t("common.date"),
          sortable: false,
          value: "created_at",
          width: "120px",
        },
        {
          text: this.$t("reports.reporter"),
          sortable: false,
          value: "reporter",
          width: "150px",
        },
        {
          text: this.$t("common.type"),
          sortable: false,
          value: "type",
        },
        { text: "", sortable: false, value: "actions", width: "1%" },
      ],
    };
  },

  watch: {
    typeFilter() {
      this.page = 1;
      this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const params = {
        page: this.page,
        per_page: this.perPage,
      };

      if (this.typeFilter) {
        params["exact_search[type]"] = this.typeFilter;
      }

      this.$store
        .dispatch("reports/fetch", params)
        .then((res) => {
          this.data = res.data;
          this.total = res.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while fetching reports.");
        });
    },
  },
};
</script>

<style></style>
