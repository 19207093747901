<template>
  <span class="report-type d-inline-block">
    <!-- Icon -->
    <v-icon :color="color" size="8" class="mr-1 pb-1">mdi-circle</v-icon>

    <!-- Text -->
    <template v-if="withCategory"> {{ getCategoryName }} / </template>
    {{ $t(`reports.types.${report.type}.title`) }}
  </span>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
    withCategory: Boolean,
  },

  computed: {
    color() {
      switch (this.report.type) {
        case "offensive_language":
        case "violence":
        case "adult_content":
          return "error";

        case "spam":
        case "scam":
        case "fake":
          return "warning";

        default:
          return null;
      }
    },

    getCategoryName() {
      return (
        this.report.category[0].toUpperCase() + this.report.category.slice(1)
      );
    },
  },
};
</script>

<style lang="scss"></style>
